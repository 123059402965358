<template>
  <v-container fluid class="pa-0">
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <v-row>
        <v-col flex>
          <div class="back-to-projects" @click="handleBackClick">
            <v-icon color="blue">mdi-chevron-left</v-icon>
            <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
              {{ $t('milestone.edit_milestone.back_to_milestones') }}
            </p>
          </div>
        </v-col>
        <v-col v-if="showTestRunsView" cols="auto" class="pointer" @click="closeShowTestRunsView">
          <v-icon>mdi-close</v-icon>
        </v-col>
      </v-row>
      <template v-if="showTestRunsView">
        <div class="d-flex align-center">
          <div v-if="isEditing">
            <v-text-field v-model="selectedMilestone.name" @keyup.enter="saveHeader" class="title-edit-field"
                          @blur="saveHeader" hide-details></v-text-field>
          </div>
          <div v-else>
            <div class="d-flex">
              <p class="text-start display-1 my-2">{{ selectedMilestone.name }}</p>
              &nbsp;&nbsp;&nbsp;
              <v-icon color="delete" @click="editHeader" style="cursor: pointer" size="35px">
                mdi-pencil-outline
              </v-icon>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div v-if="isEditing">
            <v-text-field v-model="selectedMilestone.description" @keyup.enter="saveHeader"
                          class="subdescription-edit-field" @blur="saveHeader" hide-details></v-text-field>
          </div>
          <div v-else>
            <div class="d-flex">
              <p class="text-start display-10 my-2">{{ selectedMilestone.description }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <p class="text-start display-10 my-2">{{ $t('Due Date:') }}</p>
          <v-menu v-model="menu1" :close-on-content-click="false" :max-width="'290px'">
            <template v-slot:activator="{ on }">
              <v-text-field color="blue" prepend-inner-icon="mdi-calendar-blank-outline"
                            class="date-picker-field" :value="selectedMilestone.dueAt" clearable readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="selectedMilestone.dueAt" @change="menu1 = false"></v-date-picker>
          </v-menu>
        </div>

        <div class="mt-6 d-flex">
          <v-chip @click="changeFilter('all')" :class="{ 'blue--text': tableFilter === 'all' }" width="200px"
                  :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'" label>
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-2">{{ items.length - selectedRunLength }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip @click="changeFilter('selected')" :class="{ 'blue--text': tableFilter === 'selected' }"
                    width="200px" :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'" label>
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-2">{{ selectedRunLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="d-flex align-center justify-space-between mt-5">
          <v-col :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 12" class="d-flex align-center">
            <v-text-field v-model="searchFilter" type="text" color="blue" prepend-inner-icon="mdi-magnify"
                          placeholder="Search by name, email, etc." class="search_input" dense single-line filled
                          hide-details clear-icon="body-2"></v-text-field>
          </v-col>
          <v-col flex class="d-flex justify-space-between align-center">
            <ProjectUserFilter @applyFilters="applyFilters" />
            <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
              <template v-slot:activator="{ on }">
                <v-btn class="pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                  <v-icon size="16px">
                    mdi-cog-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
                <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('Columns')
                  }}
                </v-list-item>
                <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item"
                             :key="index">
                  <v-checkbox v-model="header.isSelected" :value="header.isSelected"
                              :true-value="true" :false-value="false" off-icon="mdi-checkbox-blank"
                              class="ma-0 pa-0" color="blue" :size="16" hide-details>
                    <template v-slot:label>
                      <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-list-item>
                <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                  <div class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                       :class="{ 'blue--text font-weight-semibold ': isColumnFilter }">
                    {{ $t('Restore default') }}
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="isAnyFilterApplied" class="mt-3">
          <v-chip color="gray-light" class="ma-2 gray-ish--text font-weight-semi-bold"
                  v-for="role in this.appliedFilters.roles" :key="role.id" label>
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip color="gray-light" class="ma-2 gray-ish--text font-weight-semi-bold"
                  v-for="project in this.appliedFilters.projects" :key="project.id" label>
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip color="gray-light" class="ma-2 gray-ish--text font-weight-semi-bold"
                  v-for="tag in this.appliedFilters.tags" :key="tag.id" label>
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn class="text-capitalize mt-1" color="blue" text @click="clearAll">{{ $t('clearAll') }}</v-btn>
        </v-row>
        <MilestoneTestRunTable :usersData="filteredTestRuns" :tableFilter="tableFilter"
                          :filteredHeaders="filteredHeaders" :filteredItems="filteredTestRuns"
                          @updateUser="updateUser" />
      </template>

      <v-row justify="end" class="mt-4">
        <v-col cols="12">
          <v-flex class="mt-6 d-sm-flex justify-end">
            <v-btn v-if="showTestRunsView" dark large color="gray-100"
                   class="text-capitalize font-weight-bold black--text mr-4 mt-2"
                   :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'" @click="handleBackClick">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn v-if="showTestRunsView && toBeSelectedRunLength && tableFilter === 'all'" dark large
                   color="blue" class="text-capitalize font-weight-bold black--white mr-4 mt-2"
                   :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'" @click="addTestRuns">
              {{ $t('projects.create_project.add') }} {{ toBeSelectedRunLength }}
            </v-btn>
            <v-btn v-if="showTestRunsView && toBeSelectedRunLength && tableFilter === 'selected'" dark
                   large color="blue" class="text-capitalize font-weight-bold black--white mr-4 mt-2"
                   :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'" @click="removeTestRuns">
              {{ $t('projects.create_project.remove') }} {{ toBeSelectedRunLength }}
            </v-btn>
            <v-btn v-if="!showTestRunsView || (showTestRunsView && !toBeSelectedRunLength)" dark large
                   color="blue" class="text-capitalize font-weight-bold mt-2"
                   :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'" @click="updateMilestone">
              {{ $t('Save') }}
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog :title="$t('milestone.edit_milestone.close_dialog.title')" v-model="showConfirmBackDialog"
                          :content="$t('milestone.edit_milestone.close_dialog.title')" @close="handleCloseClick"
                          @handleConfirmClick="handleConfirmClick" />
    <RemoveTestRunDialog :title="$t('milestone.close_remove_dialog.title')"
                         :content="$t('milestone.close_remove_dialog.title')"
                         :content_part2="$t('milestone.close_remove_dialog.content_part1')"
                         :btn_label="$t('milestone.close_remove_dialog.remove_button')"
                         v-model="showConfirmRemoveTestRunDialog"
                         @close="handleCloseClick" @handleConfirmClick="handleRemoveConfirmClick" />
  </v-container>
</template>


<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import MilestoneTestRunTable from '@/components/Milestone/MilestoneTestRunTable.vue';
import ProjectUserFilter from '@/components/Milestone/MilestoneTestRunFilter.vue';
import RemoveTestRunDialog from '@/components/Milestone/MilestoneEditRemoveDialog.vue';
import { testruns } from '@/constants/data.js';
import { MilestoneTestRunTableHeader } from '@/constants/grid.js';
import { createNamespacedHelpers } from 'vuex';
import { showSuccessToast } from '@/utils/toast';

const { mapActions, mapState } = createNamespacedHelpers('milestone');
const  { mapState: mapRunState, mapActions: mapRunActions } = createNamespacedHelpers('run');

export default {
  name: 'MilestoneEditView',
  components: {
    ProjectDiscardDialog,
    MilestoneTestRunTable,
    ProjectUserFilter,
    RemoveTestRunDialog,
  },
  data() {
    return {
      imageSrc: '',
      users: testruns,
      oldMilestone: null,
      form: {
        projectName: '',
        description: '',
        users: [],
      },
      isColumnFilter: false,
      showConfirmRemoveTestRunDialog: false,
      showConfirmBackDialog: false,
      showConfirmRemoveDialog: false,
      showTestRunsView: true,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      mainFilters: {
        selectedRoles: [],
        testCases: [0, 1000],
        users: [0, 100],
        selectedTags: [],
        dateRange: {
          start: '',
          end: '',
        },
        progress: [0, 100],
      },
      mainFilter: false,
      headers: MilestoneTestRunTableHeader,
      menu1: false,
      startDate: "",
      isEditing: false,
    };
  },
  computed: {
    ...mapState(['selectedMilestone']),
    ...mapRunState(['items']),
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedRunLength() {
      return this.filteredTestRuns.filter((run) => run.toBeSelected).length;
    },
    selectedRunLength() {
      return this.filteredTestRuns.filter((run) => run.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredHeaders() {
      return this.headers.filter((header) => header.isSelected);
    },
    filteredMenuHeaders() {
      return this.headers.filter((header) => header.text != 'Actions');
    },
    filteredTestRuns() {
      if (this.searchFilter && this.mainFilter == false) {
        return this.items.filter((item) => this.matchesFilter(item));
      } else if (this.mainFilter == true && !this.searchFilter) {
        return this.items.filter(item => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testcases < this.mainFilters.testCases[0] || item.testcases > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.selectedTags.length > 0 && !this.mainFilters.selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < this.mainFilters.users[0] || item.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
      } else if (this.mainFilter == true && this.searchFilter) {
        const MainFiltered = this.items.filter(item => {
          if (this.mainFilters.selectedRoles.length > 0 && !this.mainFilters.selectedRoles.includes(item.priority)) {
            return false;
          }
          if (item.testcases < this.mainFilters.testCases[0] || item.testcases > this.mainFilters.testCases[1]) {
            return false;
          }
          if (this.mainFilters.dateRange.start && this.mainFilters.dateRange.end) {
            const itemDate = new Date(item.creationdate);
            const startDate = new Date(this.mainFilters.dateRange.start);
            const endDate = new Date(this.mainFilters.dateRange.end);
            if (itemDate < startDate || itemDate > endDate) {
              return false;
            }
          }
          if (this.mainFilters.selectedTags.length > 0 && !this.mainFilters.selectedTags.includes(item.tag)) {
            return false;
          }
          if (item.progress < this.mainFilters.users[0] || item.progress > this.mainFilters.users[1]) {
            return false;
          }
          return true;
        });
        return MainFiltered.filter((item) => this.matchesFilter(item));
      }
      return this.items;
    },
  },
  watch: {
    currentOrg: 'refreshData',
    filteredTestRuns(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
    mounted() {
      this.refreshData();
    },
  },
  created() {
    this.findMilestone(this.$route.params.handle);
    const handle = this.$route.params.handle;
    this.getAllTestRuns({ handle, projectKey: this.$route.params.key, perPage: 99999, currentPage: 0 });
  },
  methods: {
    ...mapActions(['update', 'find']),
    ...mapRunActions(['getAllTestRuns']),
    handleRemoveConfirmClick() {
    },
    editHeader() {
      this.isEditing = true;
    },
    saveHeader() {
      this.isEditing = false;
    },
    updateMilestone() {
      const item = "";
      const payload = {
        name: this.selectedMilestone.name,
        description: this.selectedMilestone.description,
        dueDate: this.selectedMilestone.dueAt,
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        milestoneId: this.selectedMilestone.uid,
        payload: payload,
      }).then(() => {
        showSuccessToast(this.$swal, this.$t('milestone.update_milestone_success'));
        this.$router.push({
          name: 'Milestones',
          params: {
            handle: this.$route.params.handle,
            key: this.$route.params.key,
          },
        });
      }).catch(error => {
        console.error('Failed to update milestone:', error);
      });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleRemoveClick() {
      this.showConfirmRemoveDialog = true;
    },
    handleConfirmRemoveClick() {
      this.showConfirmRemoveDialog = false;
      this.removeTestRuns();
    },
    handleCloseRemoveClick() {
      this.showConfirmRemoveDialog = false;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({
        name: 'Milestones',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    },
    handleConfirmOpenClick(status) {
      this.$router.push({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
      this.handleCloseClick();
    },
    closeShowTestRunsView() {
      this.showTestRunsView = false;
      this.toBeSelectedUser = [];
      this.$router.push({
        name: 'MilestoneCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addTestRuns() {
      this.items.forEach((testRun) => {
        if (testRun.toBeSelected) {
          this.$set(testRun, 'selected', true);
          this.$set(testRun, 'toBeSelected', false);
        }
      });
    },
    removeTestRuns() {
      this.items.forEach((testRun) => {
        if (testRun.toBeSelected) {
          this.$set(testRun, 'selected', false);
          this.$set(testRun, 'toBeSelected', false);
        }
      });
    },
    handleColumnReset() {},
    updateUser(user) {
      const index = this.selectedMilestone?.customFields.testRuns.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.mainFilters = filters;
      this.mainFilter = true;

    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      return item.name.toLowerCase().includes(lowerCaseFilter);
    },
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    async refreshData() {
      console.log('uid');
      this.findMilestone(this.$route.params.handle);
    },
    findMilestone(handle) {
      this.find({ handle: handle, projectKey: this.$route.params.key, uid: this.$route.params.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.search_input {
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 300px;
  }
}

.project-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}

.title-edit-field {
  font-size: 34px;
}

.subdescription-edit-field {
  font-size: 17px;
  width: 600px;
}

.date-picker-field {
  width: 100px
}
</style>
